import { FC } from 'react';

import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';

import { createUrl } from '@hultafors/solidgear/helpers';
import { useGlobal } from '@hultafors/solidgear/hooks';
import { DualProductBlockFragment } from '@hultafors/solidgear/types';

import { ButtonLink } from '../ButtonLink/ButtonLink';

import {
  ContentWrapper,
  Dash,
  DualProductBlockStyled,
  ImagesContainer,
  ImageWrapper,
  Inner,
  Label,
  Outer,
  Title,
} from './DualProductBlock.styled';

export const DualProductBlock: FC<DualProductBlockFragment> = ({
  images,
  title,
  label,
  ctaLabel,
  ctaUrl,
  fullWidth,
  textColor,
}) => {
  const { settings } = useGlobal();

  const sizes = `(min-width: ${breakpoints.desktop}) 50vw, 100vw`;

  return (
    <DualProductBlockStyled $fullWidth={fullWidth}>
      <Outer>
        <Inner>
          <ContentWrapper $textColor={textColor?.hex}>
            {label && <Label>{label}</Label>}
            <Dash $borderColor={textColor?.hex} />
            {title && <Title type="h2">{title}</Title>}
            <ButtonLink
              disabled={false}
              href={createUrl(ctaUrl ?? '', settings)}
              className="White"
            >
              {ctaLabel}
            </ButtonLink>
          </ContentWrapper>
        </Inner>
      </Outer>
      <ImagesContainer>
        <ImageWrapper>
          <Image
            src={images[0]?.responsiveImage?.src ?? ''}
            alt={images[0]?.alt ?? ''}
            placeholder="blur"
            blurDataURL={images[0]?.responsiveImage?.base64 ?? ''}
            sizes={sizes}
            fill
          />
        </ImageWrapper>

        {images[1] && (
          <ImageWrapper>
            <Image
              src={images[1]?.responsiveImage?.src ?? ''}
              alt={images[1]?.alt ?? ''}
              placeholder="blur"
              blurDataURL={images[1]?.responsiveImage?.base64 ?? ''}
              sizes={sizes}
              fill
            />
          </ImageWrapper>
        )}
      </ImagesContainer>
    </DualProductBlockStyled>
  );
};
